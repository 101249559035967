import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { StatusBar, Style } from "@capacitor/status-bar";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { init as initBadge } from "./badge/index";

// Init for every environment, including "web"
async function commonInit() {
  return;
}

// Init for native platforms only (ios | android)
async function nativeInit() {
  if (Capacitor.isPluginAvailable("StatusBar")) {
    await StatusBar.setStyle({ style: Style.Light });
  }
  if (Capacitor.isPluginAvailable("App")) {
    window.appInfos = await App.getInfo();
  }
  await initBadge();
  if (Capacitor.isPluginAvailable("CapacitorUpdater")) {
    await CapacitorUpdater.notifyAppReady();
  }
}

// Android specific init
async function androidInit() {
  return;
}

// Ios specific init
async function iOSInit() {
  if (Capacitor.isPluginAvailable("Keyboard")) {
    await Keyboard.setAccessoryBarVisible({ isVisible: true });
  }
}

async function platformSpecificInit() {
  const platform = Capacitor.getPlatform();
  const isNative = Capacitor.isNativePlatform();

  if (isNative) {
    await nativeInit();
  }
  if (platform === "ios") {
    await iOSInit();
  } else if (platform === "android") {
    await androidInit();
  }
}

async function initCapacitor() {
  await commonInit();
  await platformSpecificInit();
  console.info("Clovis capacitor config has been set");
  return null;
}

export { initCapacitor };
