import { z } from "zod";

const url = new URL(document.URL);

const viteEnv = import.meta.env;

let HOST: string = url.host;
let PROTOCOL: string = url.protocol;

/* If we are building the stack in the CI, or if the stack is running inside Capacitor 
   with native files embeded, we have to force the HOST and PROTOCOL and not fetch them from the browser */
if (viteEnv?.VITE_SERVER_DOMAIN) {
  HOST = viteEnv.VITE_SERVER_DOMAIN as string;
  PROTOCOL = "https:";
}

console.info("App launched with HOST", HOST);
console.info("App launched with PROTOCOL", PROTOCOL);

const WS_PROTOCOL = PROTOCOL === "https:" ? "wss:" : "ws:";
/* The app id that is unique on phones, and when pushing to stores */
const NATIVE_APP_ID = HOST.includes("app.clovis.app")
  ? "app.clovis.app"
  : "dev.clovis.app";
const PRODUCTION_GOOGLE_PLAY_APP_URL =
  "https://play.google.com/store/apps/details?id=app.clovis.app";
const PRODUCTION_IOS_APP_URL =
  "https://apps.apple.com/mr/app/clovis-app-for-construction/id1615838179";
const STAGING_GOOGLE_PLAY_APP_URL =
  "https://play.google.com/store/apps/details?id=dev.clovis.app";
const STAGING_IOS_APP_URL =
  "https://apps.apple.com/mr/app/clovis-app-for-construction/id1615838179";

// Compute some values for the env from deployment context if they are not already defined.
const computedEnv = {
  VITE_ANDROID_APP_STORE_URL:
    NATIVE_APP_ID === "app.clovis.app"
      ? PRODUCTION_GOOGLE_PLAY_APP_URL
      : STAGING_GOOGLE_PLAY_APP_URL,
  /* VITE_APP_BASE_URL is the full url */
  VITE_APP_BASE_URL: `${PROTOCOL}//${HOST}`,
  VITE_BRYNTUM_EXPORT_PDF_SERVER_URL: `${PROTOCOL}//${HOST}/api/bryntum-export/`,
  VITE_HASURA_SUBSCRIPTION_URL: `${WS_PROTOCOL}//${HOST}/api/graphql`,
  VITE_HASURA_URL: `${PROTOCOL}//${HOST}/api/graphql`,
  VITE_IOS_APP_STORE_URL:
    NATIVE_APP_ID === "app.clovis.app"
      ? PRODUCTION_IOS_APP_URL
      : STAGING_IOS_APP_URL,
  VITE_IS_PREVIEW: HOST.includes("pr-"),
  VITE_IS_PRODUCTION: HOST.includes("app.clovis.app"),
  VITE_IS_STAGING: HOST.includes("staging.clovis.dev"),
  // @ts-expect-error window.Cypress is defined into during our e2e tests
  VITE_IS_TEST: Boolean(window?.Cypress),
  VITE_NATIVE_APP_ID: NATIVE_APP_ID,
  VITE_PDFTRON_WEBVIEWER_SERVER_URL: `${PROTOCOL}//${HOST}/api/pdftron`,
};

export const env = z
  .object({
    DEV: z.boolean().optional(),
    VITE_ANDROID_APP_STORE_URL: z.string().nonempty(),
    VITE_APP_BASE_URL: z.string().nonempty(),
    VITE_APP_VERSION: z.string().nonempty(),
    VITE_AUTH0_AUDIENCE: z.string().nonempty(),
    VITE_AUTH0_CLIENT_ID: z.string().nonempty(),
    VITE_AUTH0_DOMAIN: z.string().nonempty(), // domain of auth0 to target, auth.clovis.dev or auth.clovis.app
    VITE_BRYNTUM_EXPORT_PDF_SERVER_URL: z.string().nonempty(),
    VITE_FIREBASE_API_KEY: z.string().nonempty(),
    VITE_FIREBASE_APP_ID: z.string().nonempty(),
    VITE_FIREBASE_AUTH_DOMAIN: z.string().nonempty(),
    VITE_FIREBASE_MEASUREMENT_ID: z.string().nonempty(),
    VITE_FIREBASE_MESSAGING_SENDER_ID: z.string().nonempty(),
    VITE_FIREBASE_PROJECT_ID: z.string().nonempty(),
    VITE_FIREBASE_STORAGE_BUCKET: z.string().nonempty(),
    VITE_FIREBASE_VAPIDKEY: z.string().nonempty(),
    VITE_FULLSTORY_ORG_ID: z.string().optional(),
    VITE_GOOGLEMAP_KEY: z.string().nonempty(),
    VITE_HASURA_SUBSCRIPTION_URL: z.string().nonempty(),
    VITE_HASURA_URL: z.string().nonempty(),
    VITE_INTERCOM_APP_ID: z.string().nonempty(),
    VITE_IOS_APP_STORE_URL: z.string().nonempty(),
    VITE_IS_PRODUCTION: z.boolean().optional(),
    VITE_IS_STAGING: z.boolean().optional(),
    VITE_IS_TEST: z.boolean().optional(),
    VITE_NATIVE_APP_ID: z.string().nonempty(),
    VITE_NGROK_AWS_MINIO_ENDPOINT: z.string().optional(),
    VITE_PDFTRON_LICENSE_KEY: z.string().min(1).optional(),
    VITE_PDFTRON_WEBVIEWER_SERVER_URL: z.string().nonempty(),
    VITE_RELEASE: z.string().optional(),
    VITE_RELEASE_DATE: z.string().optional(),
    VITE_SEGMENT_API_KEY: z.string().nonempty(),
    VITE_SENTRY_DSN: z.string().optional(),
    VITE_SENTRY_ORG_SLUG: z.string().optional(),
    VITE_STREAM_CHAT_API_KEY: z.string().nonempty(),
    VITE_TWICPICS_CLOVIS_CDN: z.string().nonempty(),
    VITE_TWICPICS_DOMAIN: z.string().nonempty(),
  })
  // import.meta.env is not available in storybook context
  .parse({ ...computedEnv, ...(viteEnv ?? process.env) });
export type Env = typeof env;
