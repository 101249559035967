import type { Auth0ProviderOptions } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import * as React from "react";
import { useLocation } from "react-router";
import { env } from "~/config/env";
import { auth0Cache } from "./cache";
import { canUseNative, getNativeAppRedirectUri } from "./utils";

interface Auth0ConfigProps {
  children: React.ReactNode;
}

function WebAuth0Config(props: Auth0ConfigProps) {
  // Will build cache options depending if we are on test environment
  // and if we are on mobile or web environment
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const login_hint = urlParams.get("login_hint") ?? undefined;
  const screen_hint = urlParams.get("screen_hint") ?? undefined;
  const impersonation_email = urlParams.get("impersonation_email") ?? undefined;

  const isNative = Capacitor.isNativePlatform();
  // @ts-expect-error window.Cypress is defined into during our e2e tests
  // On native platform we want to use localstorage to be logged when app is closed/reopened
  // On Cypress we want to cache token in localstorage to easily re-use it between our tests
  const useLocalstorage = Boolean(isNative || window.Cypress);

  // the "login_hint" and others options will be retrieved into "withAuthenticationRequired"
  return (
    <Auth0Provider
      // We must use this trick to make it work with AuthenticatedLinkRedirect
      // otherwise the React implementation of auth0 will not pickup changes into the
      // login_hint or screen_hint after it did instenciate once
      key={`${login_hint}${screen_hint}`}
      domain={env.VITE_AUTH0_DOMAIN}
      clientId={env.VITE_AUTH0_CLIENT_ID}
      login_hint={login_hint}
      screen_hint={screen_hint}
      redirectUri={`${env.VITE_APP_BASE_URL}${window.location.search}`}
      audience={env.VITE_AUTH0_AUDIENCE}
      scope="openid profile email offline_access"
      cacheLocation={useLocalstorage ? "localstorage" : "memory"}
      cache={auth0Cache}
      impersonation_email={impersonation_email}
      useRefreshTokens={true} // If you want to change the lifetime of the refresh token or the ID token on staging, click on this link (https://manage.auth0.com/dashboard/eu/dev-clovis-app/applications/8RPZMLzzcthFg3jctuQXv3HYHjYxy4sU/settings) and modify the value of the "Maximum Refresh Token Lifetime" or "Maximum ID Token Lifetime" parameter.
      prompt={"select_account"} // this parameter force the user to select an account when login with Microsoft SSO (see : https://github.com/nextauthjs/next-auth/issues/638#issuecomment-1331621257)
    >
      {props.children}
    </Auth0Provider>
  );
}

function getNativeContextRedirectUri(
  context: Parameters<NonNullable<Auth0ProviderOptions["buildRedirectUri"]>>[0],
  default_uri: Parameters<
    NonNullable<Auth0ProviderOptions["buildRedirectUri"]>
  >[1]
) {
  // When retrieve a silent token auth0-spa-js will
  // need to pass a redirect_uri parameter, however, the redirect is never really called
  // however, if the redirect_uri and the current url diverge, we'll get an error telling
  // that cross origin is not allowed, preventing the app to refresh / renew a token
  // So in that specific case, we don't want to pass our nativeRedirectUri to auth0-spa-js
  // See: https://github.com/auth0-samples/auth0-ionic-samples/issues/235
  if (context === "_getTokenFromIFrame") {
    return env.VITE_APP_BASE_URL;
  }
  return (
    default_uri ??
    getNativeAppRedirectUri("", new URLSearchParams(window.location.search))
  );
}

function NativeAuth0Config(props: Auth0ConfigProps) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const login_hint = urlParams.get("login_hint") ?? undefined;
  const screen_hint = urlParams.get("screen_hint") ?? undefined;
  // Will build cache options depending if we are on test environment
  // and if we are on mobile or web environment
  // on native the "login_hint" and others options will be retrieved into "withAuthenticationRequired"
  return (
    <Auth0Provider
      key={`${login_hint}${screen_hint}`}
      domain={env.VITE_AUTH0_DOMAIN}
      clientId={env.VITE_AUTH0_CLIENT_ID}
      redirectUri={getNativeAppRedirectUri("", urlParams)}
      buildRedirectUri={getNativeContextRedirectUri}
      audience={env.VITE_AUTH0_AUDIENCE}
      scope="openid profile email offline_access"
      login_hint={login_hint}
      screen_hint={screen_hint}
      cache={auth0Cache}
      maxAge={1200000}
      useRefreshTokens={true} // If you want to change the lifetime of the refresh token or the ID token on staging, click on this link (https://manage.auth0.com/dashboard/eu/dev-clovis-app/applications/8RPZMLzzcthFg3jctuQXv3HYHjYxy4sU/settings) and modify the value of the "Maximum Refresh Token Lifetime" or "Maximum ID Token Lifetime" parameter.
      cacheLocation={"memory"}
      prompt={"select_account"} // this parameter force the user to select an account when login with Microsoft SSO (see : https://github.com/nextauthjs/next-auth/issues/638#issuecomment-1331621257)
    >
      {props.children}
    </Auth0Provider>
  );
}

function Auth0Config(props: Auth0ConfigProps) {
  if (canUseNative() === false) {
    return <WebAuth0Config {...props} />;
  }
  return <NativeAuth0Config {...props} />;
}

export { Auth0Config };
