import { Capacitor } from "@capacitor/core";
import { Badge } from "@capawesome/capacitor-badge";

async function init() {
  if (Capacitor.isPluginAvailable("Badge")) {
    const isSupported = await Badge.isSupported();
    if (isSupported) {
      await Badge.requestPermissions();
    }
  }
}

export { init };
