import { Capacitor } from "@capacitor/core";
import { Badge } from "@capawesome/capacitor-badge";

export function useBadge() {
  const pluginAvailable = Capacitor.isPluginAvailable("Badge");
  const isNativePlatform = Capacitor.isNativePlatform();
  const canUseBadge = pluginAvailable && isNativePlatform;
  const setCount = async (count: number) => {
    if (canUseBadge) {
      await Badge.set({ count });
    }
  };

  const increaseCount = async () => {
    if (canUseBadge) {
      await Badge.increase();
    }
  };

  return {
    increaseCount,
    setCount,
  };
}
