import * as React from "react";
import { useUser } from "~/config/user/UserProvider";
import type { BasicMultiSelectFieldInputProps } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { createComponentHook } from "~/types";
import {
  useGetOrgProjectTemplatesQuery,
  useGetProjectsQuery,
} from "./ProjectsMultiSelectFieldInput.graphql";

const useProjectMultiSelectFieldInput = createComponentHook(
  (
    props: React.PropsWithChildren<
      Omit<BasicMultiSelectFieldInputProps<any>, "options"> & {
        onlyTemplates?: boolean;
      }
    >
  ) => {
    const queryContext = React.useMemo(
      () => ({ additionalTypenames: ["projects"] }),
      []
    );
    const [{ data }] = useGetProjectsQuery({ context: queryContext });

    const userOrgId = useUser().user.active_org?.org.id;

    const [{ data: orgProjectTemplate }] = useGetOrgProjectTemplatesQuery({
      context: queryContext,
      variables: {
        orgId: userOrgId ?? "",
      },
    });

    let projects = [];
    if (props.onlyTemplates) {
      projects = [
        ...(data?.projects
          .filter((p) => p.org_project_summaries?.[0]?.is_project_template)
          .map((p) => ({
            avatar: p.avatar,
            is_project_template:
              p.org_project_summaries?.[0]?.is_project_template,
            label: p.name,
            type: BasicMultiSelectFieldInputOptionType.PROJECT,
            value: p.id,
          })) ?? []),
        ...(orgProjectTemplate?.getOrgProjectTemplates?.templates?.map((p) => ({
          avatar: p.avatar,
          is_project_template: true,
          label: p.name,
          type: BasicMultiSelectFieldInputOptionType.PROJECT,
          value: p.id,
        })) ?? []),
      ];
    } else {
      projects =
        data?.projects.map((p) => ({
          avatar: p.avatar,
          is_project_template:
            p.org_project_summaries?.[0]?.is_project_template,
          label: p.name,
          type: BasicMultiSelectFieldInputOptionType.PROJECT,
          value: p.id,
        })) ?? [];
    }
    return {
      state: {
        projects,
      },
    };
  }
);

const ProjectsMultiSelectFieldInput = React.forwardRef<
  HTMLInputElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Omit<BasicMultiSelectFieldInputProps<any>, "options"> & {
    onlyTemplates?: boolean;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(function ProjectsMultiSelectFieldInput(props, _) {
  const { state } = useProjectMultiSelectFieldInput(props);

  // order state.project to place projects with is_project_template first
  state.projects.sort((a, b) => {
    if (a.is_project_template && !b.is_project_template) {
      return -1;
    }
    if (!a.is_project_template && b.is_project_template) {
      return 1;
    }
    return 0;
  });

  return <BasicMultiSelectFieldInput options={state.projects} {...props} />;
});

export { ProjectsMultiSelectFieldInput };
